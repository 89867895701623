import React, { useState, useEffect } from 'react'
import { FireFilled, LoadingOutlined } from '@ant-design/icons'
import { Button, Spin } from 'antd'
import { useDispatch } from 'react-redux'
import './VoteButton.css'
import axios from 'axios';

function VoteButton(props) {
    const { voteCount, isVoted, onVoteButtonClick, coinID } = props;
    const [voteButtonClass, setVoteButtonClass] = useState('vote-button')
    const [isLoading, setIsLoading] = useState(false)
    const dispatch = useDispatch()
    const clientIP = localStorage.getItem('rfc_client_ip') ? localStorage.getItem('rfc_client_ip') : null

    useEffect(() => {
        if(isVoted) {
            setVoteButtonClass('vote-button-voted')
        } else {
            setVoteButtonClass('vote-button')
        }
    }, [isVoted])

    // if not voted, trigger vote fuction and then reload the table
    const handleVote = async () => {
        if(!isVoted && clientIP) {
            setIsLoading(true)

            try {
                const url = 'https://backend.rugfreecoins.com/api/v1/coins/brc20/token/vote';

                const headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
                };
                const data = {
                    "ip_address": clientIP,
                    "inscription_number": coinID
                };

                try {
                    const response = await axios.post(url, data, { headers });
                    // return true
                    onVoteButtonClick();
    
                } catch (error) {
                    console.log('Failed to send data.');
                    console.log(error);
                    // Handle error
                }

            } catch (error) {
                console.log('ERROR on casting vote action', error)
            }

            setIsLoading(false)
        }
    }

    const antIcon = (
        <LoadingOutlined
          style={{
            fontSize: 22,
          }}
          spin
        />
      );
      

    return (
        <div>
            <Button className={`${voteButtonClass}`} onClick={() => handleVote()}>
                {
                    !isLoading ? (
                    <div className='d-flex justify-content-around'>
                        <div className='fire-icon' >
                            <FireFilled style={{ fontSize: '14px', color: '#FFA31A' }} />
                        </div>
                        <div>{voteCount}</div>
                    </div>
                    ) : (
                        <div className='text-center'>
                            <Spin size="small" indicator={antIcon} />
                        </div>
                    )
                }
            </Button>
        </div>
    )
}

export default VoteButton