import React, { useEffect, useState } from 'react'
import './AuditTable.css'
import { Table, notification, Button, Select } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { fetchAllTimeBestTokens } from '../../Redux/AllTimeBestTokensSlice'
import Badges from '../Badges/Badges'
import MarketCap from '../MarketCap/MarketCap'
import RiskLevel from '../RiskLevel/RiskLevel'
import moment from 'moment'
import VoteButton from '../VoteButton/VoteButton'
import TokenName from '../TokenName/TokenName'
import Chain from '../Chain/Chain'
import bsc_logo from '../../images/bsc.png'
import eth_logo from '../../images/eth.png'
import tron_logo from '../../images/tron.png'

function AllTimeBestTable(props) {

    // table data related variables
    const { loading, data, error } = useSelector((state) => state.allTimeBestTokens)
    const { searchTerm } = props
    const dispatch = useDispatch()

    // pagination related data
    const [pageNumber, setPageNumber] = useState(1)
    const [selectedChain, setSelectedChain] = useState(null);
    const [isAudited, setIsAudited] = useState(null);
    const [sortByMarketCap, setSortByMarketCap] = useState(null);
    const [filteredData, setFilteredData] = useState(data)

    // antd notifications without duplicating
    const notificatioKey = 'error';

    // fetch data from API
    useEffect(() => {
        let searchObject = {
            pageNumber: pageNumber,
            searchTerm: searchTerm
        }
        dispatch(fetchAllTimeBestTokens(searchObject))
    }, [dispatch, pageNumber, searchTerm])

    // error nofitications
    useEffect(() => {
        if (error) {
            notification['error']({
                key: notificatioKey,
                message: 'Oops!',
                description: error
            });
        }
    }, [error])

    const handleNext = () => {
        let _pageNumber = pageNumber
        setPageNumber(_pageNumber + 1)
    }


    useEffect(() => {

        let updatedFilteredData = data;

        if (selectedChain === "bsc") {
            updatedFilteredData = data.filter((record) => record.bsc_contract_address !== null);
        } else if (selectedChain === "eth") {
            updatedFilteredData = data.filter((record) => record.ethereum_contract_address !== null);
        } else if (selectedChain === "tron") {
            updatedFilteredData = data.filter((record) => record.tron_contract_address !== null);
        }

        if (isAudited === "audited") {
            updatedFilteredData = updatedFilteredData.filter((record) => record.is_audited === 1);
        } else if (isAudited === "not-audited") {
            updatedFilteredData = updatedFilteredData.filter((record) => record.is_audited === 0);
        }

        if (sortByMarketCap === "ascending") {
            updatedFilteredData = [...updatedFilteredData].sort((a, b) => a.market_cap - b.market_cap);
        } else if (sortByMarketCap === "descending") {
            updatedFilteredData = [...updatedFilteredData].sort((a, b) => b.market_cap - a.market_cap);
        }


        setFilteredData(updatedFilteredData)

    }, [data, selectedChain, isAudited, sortByMarketCap])



    // filter drop down
    // const handleChainChange = (value) => {

    //     if(value === 'bsc'){
    //         setSelectedChain('bsc');
    //     }
    //     else if(value === 'eth'){
    //         setSelectedChain('eth');
    //     }
    //     else{
    //         setSelectedChain('All');
    //     }

    //     console.log(selectedChain)
    //   };

    const handleChainChange = (value) => {
        setSelectedChain(value);
    };

    const handleAuditedChange = (value) => {
        setIsAudited(value);
    };

    const handleSortByMarketCapChange = (value) => {
        setSortByMarketCap(value);
    };

    // reset filetrs
    const handleResetFilters = () => {
        setSelectedChain(null);
        setIsAudited(null);
        setSortByMarketCap(null);
    };


    // columns related data
    const columns = [
        {
            key: "id",
            title: 'No',
            dataIndex: "id",
            render: (_, record, index) => (index + 1),
        },
        {
            key: "name",
            title: 'Name',
            fixed: 'left',
            dataIndex: "name",
            render: (_, record) => (
                <TokenName id={record.id} tokenName={record.name} tokenSymbol={record.symbol} logoLink={record.logo_link} coingecko_link={record.coingecko} coinmarketcap_link={record.coinmarketcap} />
            ),
        },
        {
            key: "chain",
            title: "Chain",
            dataIndex: "chain",
            render: (_, record, index) => (
                <Chain tokenData={record} />
            ),
        },
        {
            key: "market_cap",
            title: 'Market Cap',
            render: (_, record) => <span className='marketcap-text'><MarketCap address={record.bsc_contract_address ? record.bsc_contract_address : record.ethereum_contract_address}  chain={record.bsc_contract_address ? 'bsc' : 'eth'} /></span>,
        },
        {
            key: "released_on",
            title: 'Released',
            render: (_, record) => <span>{moment(record.release_date).fromNow()}</span>,
        },
        {
            key: "risk_level",
            title: 'Risk Level',
            render: (_, record) => <RiskLevel riskLevel={record.risk_level} />,
        },
        {
            key: "Badges",
            title: 'Badges',
            dataIndex: "badges",
            render: (_, record) => <div>
                <Badges isAudited={record.is_audited} riskLevel={record.risk_level} />
            </div>
        },
        {
            key: "votes",
            title: 'Votes',
            dataIndex: "vote_count",
            render: (_, record) => <div>
                <VoteButton voteCount={record.vote_count} isVoted={record.is_voted} dispatchEvent={fetchAllTimeBestTokens({ pageNumber: pageNumber, searchTerm: searchTerm })} coinID={record.id} />
            </div>
        }
    ];

    return (
        <>
            <div className='filter-dropdown-area'>
                <div className='dropfilter-item'>
                    <div className='dropdown-item-name'>Chain</div>
                    <Select
                        value={selectedChain}
                        onChange={handleChainChange}
                        placeholder='Select Chain'
                        className='filter-drop-down'
                    >
                        <Select.Option value={null}>All</Select.Option>
                        <Select.Option value='bsc'><img className='item-icon' src={bsc_logo} />&nbsp;BSC</Select.Option>
                        <Select.Option value='eth'><img className='item-icon' src={eth_logo} />&nbsp;ETH</Select.Option>
                    </Select>
                </div>
                <div className='dropfilter-item'>
                    <div className='dropdown-item-name'>Audit</div>
                    <Select
                        value={isAudited}
                        onChange={handleAuditedChange}
                        placeholder='Audit Status'
                        className='filter-drop-down'
                    >
                        <Select.Option value={null}>All</Select.Option>
                        <Select.Option value='audited'>Audited</Select.Option>
                        <Select.Option value='not-audited'>Not Audited</Select.Option>
                    </Select>
                </div>
                <div className='dropfilter-item'>
                    <div className='dropdown-item-name'>Market Cap</div>
                    <Select
                        value={sortByMarketCap}
                        onChange={handleSortByMarketCapChange}
                        placeholder='Sort By Market Cap'
                        className='filter-drop-down'
                    >
                        <Select.Option value={null}>All</Select.Option>
                        <Select.Option value='ascending'>Low to high</Select.Option>
                        <Select.Option value='descending'>High to low</Select.Option>
                    </Select>
                </div>
                <div className='dropfilter-item'>
                <Button
                    type="link"
                    onClick={handleResetFilters}
                    style={{ marginLeft: '16px', marginTop: '20px' }}
                >
                Reset filters
                </Button>
                </div>
                
            </div>
            <div className='table'>
                <div className='table-responsive'>
                    <Table
                        rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' : 'table-row-dark'}
                        size='small'
                        columns={columns}
                        // dataSource={data}
                        dataSource={filteredData}
                        pagination={false}
                        loading={loading}
                    />

                    <div className='text-center'>
                        <div className='service-button'>
                            {
                                !loading &&
                                <Button
                                    type="primary"
                                    onClick={() => handleNext()}
                                >
                                    See More
                                </Button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AllTimeBestTable