import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import coinMarketCap from '../../images/coinmarketcap-logo.png'
import fallbackCoinImg from '../../images/no-img.png'
import moment from 'moment'


function TokenListItem(props) {

  const { itemData, type, cardType } = props;
  const [logoimg, setLogoImg] = useState(coinMarketCap)
  const [name, setName] = useState('Name')
  const [symbol, setSymbol] = useState('Symbol')
  const [info, setInfo] = useState('')
  const [infoClass, setInfoClass] = useState('')
  const [tokenLink, setTokenLink] = useState('')

  const handleImgError = () => {
    setLogoImg(fallbackCoinImg)
  }

  useEffect(() => {

    if (type == 'brc20') {

      setLogoImg(`https://ordspace.org/brc20-icon/${itemData?.ticker}.png`)

      setName(itemData?.ticker)
      setSymbol(itemData?.ticker)
      setInfo(itemData?.change + '%')
      setInfoClass('info percentage')
      setTokenLink(`brc-20/${itemData?.inscriptionNumber}/${itemData?.ticker}`)
    }
    else {
      setLogoImg(itemData?.logo_link)
      setName(itemData?.name)
      setSymbol(itemData?.symbol)
      setTokenLink(`coin-details/${itemData?.id}`)
    }

    if (type == 'recent') {
      setInfo(moment(itemData?.approved_at).fromNow())
      setInfoClass('info moment_date')
    }
  }, [])


  // console.log('in comp data: ', itemData);


  return (
    <div className='token-list-item position-relative'>
      <a className='info-link position-absolute' href={tokenLink} style={{ top: '0', left: '0', height: '100%', width: '100%', zIndex: 2 }}></a>
      <div>
        <img className='token-logo' style={{ width: '35px', height: '35px', borderRadius: '50px' }} src={logoimg} onError={handleImgError} />
        <span className='token-name'>{name}</span>
        <span className='token-symbol'>{symbol}</span>
      </div>

      <div className={infoClass}>
        {info}
      </div>
    </div>
  )
}

export default TokenListItem