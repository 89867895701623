import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';

// initialize client IP from local storage
const clientIP = localStorage.getItem('rfc_client_ip')
    ? localStorage.getItem('rfc_client_ip')
    : null

const initialState = {
    loading: false,
    data: [],
    error: ''
}

// fetch data from api
export const fetchBrc20Tokens = createAsyncThunk('fetchBrc20Tokens', async (pageNumber) => {
    const response = await axios.get(
        `https://api.ordspace.org/brc-20?page=${pageNumber}&orderBy=market&s=`
        // `https://api.coinranking.com/v2/coins?limit=50&timePeriod=24h&search=&tags[]=brc-20`
      );

    //   console.log('brc20', response);

    //   return response.data.code != 200 ? [] : response.data.data

      return response.data
})

export const brc20TokensSlice = createSlice({
    name: "brc20Tokens",
    initialState,
    extraReducers: (builder) => {
        builder.addCase(fetchBrc20Tokens.pending, (state) => {
            state.loading = true
        })
        builder.addCase(fetchBrc20Tokens.fulfilled, (state, action) => {
            state.loading = false
            state.data = action.payload.code === 200 ? action.payload.data : []
            state.error = ''
            // console.log("action.payload: brc20", action.payload)
        })
        builder.addCase(fetchBrc20Tokens.rejected, (state, action) => {
            state.loading = false
            state.data = []
            state.error = action.data.data.message
        })
    }
});

export default brc20TokensSlice.reducer