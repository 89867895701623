import React from 'react'
import { Row, Col, Card } from 'antd';
import BRC20_tokens from './BRC20_tokens/BRC20_tokens';
import RecentlyAdded from './RecentlyAdded/RecentlyAdded'
import TrendingTokens from './TrendingTokens/TrendingTokens'
import './TokenStats.css'

function TokenStats() {
  return (

    <Row className='token-brief-card-row mb-2 mb-md-4' gutter={16}>
      <Col className='ant' xs={24} md={8}>

        <Card className='home-tabs-card token-info-card' title="🔥&nbsp;Trending Tokens" bordered={true}>
          <TrendingTokens />
        </Card>

      </Col>

      <Col xs={24} md={8}>
        
        <Card className='home-tabs-card token-info-card' title="🥇&nbsp;Top BRC20 Tokens" bordered={true}>
         <BRC20_tokens />
        </Card>

      </Col>

      <Col xs={24} md={8}>
        
        <Card className='home-tabs-card token-info-card' title="🔄&nbsp;Recently Added" bordered={true}>
          <RecentlyAdded />
        </Card>
      </Col>
    </Row>

  )
}

export default TokenStats