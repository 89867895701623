import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import TokenListItem from '../../TokenListItem/TokenListItem';
import { fetchBrc20Tokens } from '../../../Redux/Brc20TokensSlice';
import { notification, Skeleton } from 'antd'

function BRC20_tokens() {

  const { loading, data, error } = useSelector((state) => state.brc20Tokens)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchBrc20Tokens(1))
  }, [dispatch])

  // antd notifications without duplicating
  const notificatioKey = 'error';

  // error nofitications
  useEffect(() => {
    if (error) {
      notification['error']({
        key: notificatioKey,
        message: 'Oops!',
        description: error
      });
    }
  }, [error])

  // console.log('BRC20 data', data)
  const newData = data?.slice()?.sort((a, b) => b.change - a.change)

 

  return (
    <div>
      {
        (loading) &&
        <div>
          <Skeleton loading={loading} avatar active paragraph={{ rows: 0, }} />
          <Skeleton loading={loading} avatar active paragraph={{ rows: 0, }} />
          <Skeleton loading={loading} avatar active paragraph={{ rows: 0, }} />
        </div>
      }
      {

        (!loading && data?.length !== 0) &&
        data?.map((item, index) => (
          index < 3 ? <TokenListItem key={index} itemData={item} type={'brc20'} /> : ''
        ))

      }
      {
        (!loading && newData?.length === 0) &&
        <div>
          <Skeleton loading={true} avatar active paragraph={{ rows: 0, }} />
          <Skeleton loading={true} avatar active paragraph={{ rows: 0, }} />
          <Skeleton loading={true} avatar active paragraph={{ rows: 0, }} />
        </div>
      }
    </div>
  )
}

export default BRC20_tokens