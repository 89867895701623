import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import fallbackCoinImg from '../../images/no-img.png'

function TickerName(props) {
    const { id, tokenName, iconUrl } = props
    const imgUrl = `https://ordspace.org/brc20-icon/${tokenName}.png`

    const [imgSrc, setImgSrc] = useState(fallbackCoinImg)

    useEffect(() => {
        // setImgSrc(iconUrl)
        setImgSrc(imgUrl)
    }, [tokenName])

    const handleImgError = () => {
        setImgSrc(fallbackCoinImg)
    }

    return (
        <div className='d-flex'>
            <Link to={ id && `/brc-20/${id}/${tokenName}`} className="my-auto">
                <div className='d-flex' style={{ minWidth: '100px', marginLeft: '0px' }}>
                    <div className='my-auto'>
                        <img src={imgSrc} alt="logo" className='token-logo' onError={handleImgError} />
                    </div>

                    <div style={{ marginLeft: '10px' }} className="my-auto">
                        <span>{tokenName}</span>
                    </div>
                </div>
            </Link>
        </div>
    )
}

export default TickerName