import {
  Card,
  Button,
  Switch,
  Form,
  Input,
  DatePicker,
  TimePicker,
  Select,
  notification,
} from "antd";
import {
  SnippetsOutlined,
  RocketOutlined,
  LinkOutlined,
  PictureOutlined,
  DollarCircleOutlined,
  StockOutlined,
  NumberOutlined,
  SendOutlined,
  TwitterOutlined,
  RedditOutlined,
} from "@ant-design/icons";
import BundledEditor from "../../Components/BundledEditor/BundledEditor";
import React, { useRef, useState, useEffect } from "react";
import { Col, Row } from "reactstrap";
import discordLogo from "../../images/discord.png";
import { useSelector } from "react-redux";
import api from "../../Services/Api";
import { useNavigate, useLocation } from "react-router-dom";
import AddHere from "../../Components/AddHere/AddHere";

function AddCoin() {
  // user profile data
  const { userProfile } = useSelector((state) => state.auth);

  const navigate = useNavigate();
  const location = useLocation();

  // token information section relates variables
  const editorRef = useRef(null); // richtext editor ref
  const [descriptionValidateStatus, setDescriptionValidateStatus] =
    useState(null);
  const [descriptionHelpMsg, setDescriptionHelpMsg] = useState(null);

  // presale token section related variables
  const [isPresale, setIsPresale] = useState(false);
  const [presaleDate, setPresaleDate] = useState(null);
  const [presaleTime, setPresaleTime] = useState(null);
  const [presaleLinkValidateStatus, setPresaleLinkValidateStatus] =
    useState(null);
  const [presaleLinkValidateMsg, setPresaleLinkValidateMsg] = useState(null);

  // token metadata section related variables
  const [releasedDate, setReleasedDate] = useState(null);
  const [tokenLogoLinkValidateStatus, setTokenLogoLinkValidateStatus] =
    useState(null);
  const [tokenLogoLinkValidateMsg, setTokenLogoLinkValidateMsg] =
    useState(null);
  const [releasedTime, setReleasedTime] = useState(null);
  const [coinMarketCapValidateStatus, setCoinMarketCapValidateStatus] =
    useState(null);
  const [coinMarketCapValidateMsg, setCoinMarketCapValidateMsg] =
    useState(null);
  const [coinGeckoValidateStatus, setCoinGeckoValidateStatus] = useState(null);
  const [coinGeckoValidateMsg, setCoinGeckoValidateMsg] = useState(null);
  const [isDoxxed, setIsDoxxed] = useState(false);

  // smart contract section related variables
  const [smartContractType, setSmartContractType] = useState("bsc");

  // social section related variables
  const [websiteValidateStatus, setWebsiteValidateStatus] = useState(null);
  const [websiteValidateMsg, setWebsiteValidateMsg] = useState(null);
  const [telegramValidateStatus, setTelegramValidateStatus] = useState(null);
  const [telegramValidateMsg, setTelegramValidateMsg] = useState(null);
  const [twitterValidateStatus, setTwitterValidateStatus] = useState(null);
  const [twitterValidateMsg, setTwitterValidateMsg] = useState(null);
  const [redditValidateStatus, setRedditValidateStatus] = useState(null);
  const [redditValidateMsg, setRedditValidateMsg] = useState(null);
  const [discordValidateStatus, setDiscordValidateStatus] = useState(null);
  const [discordValidateMsg, setDiscordValidateMsg] = useState(null);

  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    // Listen for changes in the browser history.
    const handlePopstate = () => {
      console.log(location.pathname);
      // Check if the user navigated back to the login page.
      if (location.pathname === "/add-coin" || location.pathname === "/login") {
        // Redirect the user to the home page.
        navigate("/");
      }
    };

    window.addEventListener("popstate", handlePopstate);

    // Clean up the event listener when the component unmounts.
    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, [navigate]);

  // if correctly fillout the form
  const onFinish = (values) => {
    if (!editorRef?.current.getContent()) {
      setDescriptionValidateStatus("error");
      setDescriptionHelpMsg("The Token Description field is required!");
    } else {
      setDescriptionValidateStatus(null);
      setDescriptionHelpMsg(null);
      submitToken(values);
    }
  };

  const submitToken = (values) => {
    setIsSubmitLoading(true);
    const user_id = userProfile?.id;

    let doxxed_value = 0;
    let presale_value = 0;

    // set doxxed value
    if (isDoxxed) {
      doxxed_value = 1;
    }

    // set presale value
    if (isPresale) {
      presale_value = 1;
    }

    let presale_datetime = presaleDate + " " + presaleTime;
    if (!presaleDate && !presaleTime) {
      presale_datetime = "";
    }

    let released_datetime = releasedDate + " " + releasedTime;

    let formData = new FormData();

    formData.append("name", values.token_name ? values.token_name : "");
    formData.append("symbol", values.token_symbol ? values.token_symbol : "");
    formData.append(
      "description",
      editorRef?.current.getContent() ? editorRef?.current.getContent() : ""
    );
    formData.append("telegram_link", values.telegram ? values.telegram : "");
    formData.append(
      "logo_link",
      values.token_logo_link ? values.token_logo_link : null
    );
    formData.append("website", values.website ? values.website : "");
    formData.append("twitter_link", values.twitter ? values.twitter : "");
    formData.append("reddit_link", values.reddit ? values.reddit : "");
    formData.append("discord_link", values.discord ? values.discord : "");
    formData.append(
      "bsc_contract_address",
      smartContractType === "bsc" && values.bsc_address
        ? values.bsc_address
        : ""
    );
    formData.append(
      "ethereum_contract_address",
      smartContractType === "eth" && values.eth_address
        ? values.eth_address
        : ""
    );
    formData.append(
      "tron_token",
      smartContractType === "tron" && values.tron_address
        ? values.tron_address
        : ""
    );
    formData.append(
      "kcc_token",
      smartContractType === "kcc" && values.kcc_address
        ? values.kcc_address
        : ""
    );
    formData.append(
      "solana_token",
      smartContractType === "solana" && values.solana_address
        ? values.solana_address
        : ""
    );
    formData.append(
      "polygon_token",
      smartContractType === "polygon" && values.polygon_address
        ? values.polygon_address
        : ""
    );
    formData.append(
      "avalanche_token",
      smartContractType === "avalanche" && values.avalanche_address
        ? values.avalanche_address
        : ""
    );
    formData.append(
      "maxxchain_token",
      smartContractType === "maxxchain" && values.maxxchain_address
        ? values.maxxchain_address
        : ""
    );
    formData.append(
      "dogechain_token",
      smartContractType === "dogechain" && values.dogechain_address
        ? values.dogechain_address
        : ""
    );
    formData.append(
      "pulsechain_token",
      smartContractType === "pulsechain" && values.pulsechain_address
        ? values.pulsechain_address
        : ""
    );
    formData.append(
      "basechain_token",
      smartContractType === "basechain" && values.basechain_address
        ? values.basechain_address
        : ""
    );
    formData.append(
      "shibarium_token",
      smartContractType === "shibarium" && values.shibarium_address
        ? values.shibarium_address
        : ""
    );
    formData.append(
      "other_links",
      values.other_links ? values.other_links : ""
    );
    formData.append(
      "actual_price",
      values.actual_price ? values.actual_price : ""
    );
    formData.append(
      "actual_market_cap",
      values.market_cap ? values.market_cap : ""
    );
    formData.append(
      "coingecko",
      values.coin_gecko_link ? values.coin_gecko_link : ""
    );
    formData.append(
      "coinmarketcap",
      values.coin_market_cap_link ? values.coin_market_cap_link : ""
    );
    formData.append("release_date", released_datetime);
    {
      presale_datetime && formData.append("presale_date", presale_datetime);
    }
    formData.append("user_id", user_id);
    formData.append("is_doxxed", doxxed_value);
    formData.append("is_presale", presale_value);
    formData.append(
      "presale_link",
      values.presale_link ? values.presale_link : ""
    );

    try {
      api.post("/coin", formData).then((response) => {
        if (response.status === 201) {
          notification["success"]({
            key: "success",
            message: "Congratulations 🏆",
            description: "Your coin has been submitted!",
          });

          navigate("/profile");
        }
      });
    } catch (error) {
      if (error.response.status === 422) {
        let errorList = error.response.data.errors || [];
        const keys = Object.keys(errorList);
        if (keys.length > 0) {
          keys.forEach((key) => {
            notification["error"]({
              key: errorList[key][0],
              message: "Validation Error",
              description: errorList[key][0],
            });
          });
        }
      } else {
        notification["error"]({
          key: "error",
          message: "Something went wrong",
          description: "Please Try Again",
        });
      }
    }

    setIsSubmitLoading(false);
  };

  // if there are errors in the form inputs
  const onFinishFailed = (errorInfo) => {
    if (!editorRef?.current.getContent()) {
      setDescriptionValidateStatus("error");
      setDescriptionHelpMsg("The Token Description field is required!");
    } else {
      setDescriptionValidateStatus(null);
      setDescriptionHelpMsg(null);
    }
  };

  // check the input address is a valid web address or not
  const isValidWebAddress = (address) => {
    const urlPattern =
      /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/;

    if (urlPattern.test(address)) {
      return true;
    } else {
      return false;
    }
  };

  // check the input address is a valid image url or not
  const isValidImageAddress = (address) => {
    const urlPattern = /(http)?s?:?(\/\/[^"']*\.(?:png|jpg|jpeg|gif|png|svg))/;

    if (urlPattern.test(address)) {
      return true;
    } else {
      return false;
    }
  };

  // validate the telegram link
  const isValidTelegramAddress = (address) => {
    const urlPattern =
      /(https?:\/\/)?(www[.])?(telegram|t)\.me\/([a-zA-Z0-9_-]*)\/?$/;

    if (urlPattern.test(address)) {
      return true;
    } else {
      return false;
    }
  };

  // validate the twitter link
  const isValidTwitterAddress = (address) => {
    const urlPattern = /http(?:s)?:\/\/(?:www\.)?twitter\.com\/([a-zA-Z0-9_]+)/;

    if (urlPattern.test(address)) {
      return true;
    } else {
      return false;
    }
  };

  // validate reddit link
  const isValidRedditAddress = (address) => {
    const urlPattern =
      /^(https?:\/\/)?(www\.)?reddit\.com\/(r\/[a-zA-Z0-9_]+\/comments\/)?[a-zA-Z0-9_]+(\/[a-zA-Z0-9_]+)?(\/)?(\?.*)?$/;

    if (urlPattern.test(address)) {
      return true;
    } else {
      return false;
    }
  };

  // validate discord link
  const isValidDiscordAddress = (address) => {
    const urlPattern =
      /^https?:\/\/(www\.)?discord(?:app)?\.com\/(?:invite\/)?[a-zA-Z0-9-]{2,32}$/;

    if (urlPattern.test(address)) {
      return true;
    } else {
      return false;
    }
  };

  const onChangeIsPresale = (value) => {
    if (value) {
      setIsPresale(true);
    } else {
      setIsPresale(false);
    }
  };

  const onChangeIsDoxxed = (value) => {
    if (value) {
      setIsDoxxed(true);
    } else {
      setIsDoxxed(false);
    }
  };

  const onChangePresaleDate = (date, datestring) => {
    setPresaleDate(datestring);
  };

  const onChangePresaleTime = (date, datestring) => {
    setPresaleTime(datestring);
  };

  const isValidPresaleAddress = (address) => {
    const isValid = isValidWebAddress(address);

    if (!isValid) {
      setPresaleLinkValidateStatus("error");
      setPresaleLinkValidateMsg("Please input a valid presale address!");
    } else {
      setPresaleLinkValidateStatus("success");
      setPresaleLinkValidateMsg(null);
    }
  };

  const onChangeReleasedDate = (date, datestring) => {
    setReleasedDate(datestring);
  };

  const isValidTokenLogoLink = (link) => {
    const isValid = isValidImageAddress(link);

    if (!isValid) {
      setTokenLogoLinkValidateStatus("error");
      setTokenLogoLinkValidateMsg("Please input a valid logo link!");
    } else {
      setTokenLogoLinkValidateStatus("success");
      setTokenLogoLinkValidateMsg(null);
    }
  };

  const onChangeReleasedTime = (date, datestring) => {
    setReleasedTime(datestring);
  };

  const isValidCoinMarketCapLink = (link) => {
    const isValid = isValidWebAddress(link);

    if (!isValid) {
      setCoinMarketCapValidateStatus("error");
      setCoinMarketCapValidateMsg(
        "Please input a valid coin market cap address!"
      );
    } else {
      setCoinMarketCapValidateStatus("success");
      setCoinMarketCapValidateMsg(null);
    }
  };

  const isValidCoinGeckoLink = (link) => {
    const isValid = isValidWebAddress(link);

    if (!isValid) {
      setCoinGeckoValidateStatus("error");
      setCoinGeckoValidateMsg("Please input a valid coin gecko address!");
    } else {
      setCoinGeckoValidateStatus("success");
      setCoinGeckoValidateMsg(null);
    }
  };

  const isValidWebsite = (link) => {
    const isValid = isValidWebAddress(link);

    if (!isValid) {
      setWebsiteValidateStatus("error");
      setWebsiteValidateMsg("Please input a valid web address!");
    } else {
      setWebsiteValidateStatus("success");
      setWebsiteValidateMsg(null);
    }
  };

  const isValidTelegramLink = (link) => {
    const isValid = isValidTelegramAddress(link);

    if (!isValid) {
      setTelegramValidateStatus("error");
      setTelegramValidateMsg("Please input a valid telegram link!");
    } else {
      setTelegramValidateStatus("success");
      setTelegramValidateMsg(null);
    }
  };

  const isValidTwitterLink = (link) => {
    const isValid = isValidTwitterAddress(link);

    if (!isValid) {
      setTwitterValidateStatus("error");
      setTwitterValidateMsg("Please input a valid twitter link!");
    } else {
      setTwitterValidateStatus("success");
      setTwitterValidateMsg(null);
    }
  };

  const isValidRedditLink = (link) => {
    const isValid = isValidRedditAddress(link);

    if (!isValid) {
      setRedditValidateStatus("error");
      setRedditValidateMsg("Please input a valid reddit link!");
    } else {
      setRedditValidateStatus("success");
      setRedditValidateMsg(null);
    }
  };

  const isValidDiscordLink = (link) => {
    const isValid = isValidDiscordAddress(link);

    if (!isValid) {
      setDiscordValidateStatus("error");
      setDiscordValidateMsg("Please input a valid discord link!");
    } else {
      setDiscordValidateStatus("success");
      setDiscordValidateMsg(null);
    }
  };

  const handleSmartContractTypeChange = (value) => {
    setSmartContractType(value);
  };

  return (
    <div className="mt-5 mb-5">
      <AddHere />

      <div className="container-fluid col-lg-9 col-md-10">
        <Card className="home-tabs-card">
          <Form
            className="mt-3"
            name="token_information"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <h5>Token Information</h5>

            <Row className="mt-3">
              <Col xxl="6" xl="6" lg="6" md="6" sm="12" xs="12">
                <span className="small">Token Name</span>
                <Form.Item
                  name="token_name"
                  rules={[
                    {
                      required: true,
                      message: "The Token Name field is required!",
                    },
                  ]}
                >
                  <Input
                    prefix={<SnippetsOutlined />}
                    size="large"
                    placeholder="Bitcoin"
                  />
                </Form.Item>
              </Col>

              <Col xxl="6" xl="6" lg="6" md="6" sm="12" xs="12">
                <span className="small">Token Symbol</span>
                <Form.Item
                  name="token_symbol"
                  rules={[
                    {
                      required: true,
                      message: "The Token Symbol field is required!",
                    },
                  ]}
                >
                  <Input
                    prefix={<RocketOutlined />}
                    size="large"
                    placeholder="BTC"
                  />
                </Form.Item>
              </Col>

              <Col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12">
                <span className="small">Token Description</span>
                <Form.Item
                  validateStatus={descriptionValidateStatus}
                  help={descriptionHelpMsg}
                >
                  <BundledEditor
                    onInit={(evt, editor) => (editorRef.current = editor)}
                    init={{
                      height: 400,
                      menubar: false,
                      plugins: [
                        "advlist",
                        "anchor",
                        "autolink",
                        "help",
                        "image",
                        "link",
                        "lists",
                        "searchreplace",
                        "table",
                        "wordcount",
                      ],
                      toolbar:
                        "undo redo | blocks | " +
                        "bold italic forecolor | alignleft aligncenter " +
                        "alignright alignjustify | bullist numlist outdent indent | " +
                        "removeformat | help",
                      content_style:
                        "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>

            <h5 className="mt-3">Presale Token</h5>

            <Row>
              <Col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12">
                <Form.Item name="is_presale">
                  <span className="small">Presale </span>
                  <Switch
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    onChange={onChangeIsPresale}
                  />
                </Form.Item>
              </Col>

              {isPresale && (
                <Row>
                  <Col xxl="6" xl="6" lg="6" md="6" sm="12" xs="12">
                    <span className="small">Presale Date</span>
                    <Form.Item
                      name="presale_date"
                      rules={[
                        {
                          required: true,
                          message: "The Presale Date field is required!",
                        },
                      ]}
                    >
                      <DatePicker
                        size="large"
                        className="w-100"
                        onChange={onChangePresaleDate}
                      />
                    </Form.Item>
                  </Col>

                  <Col xxl="6" xl="6" lg="6" md="6" sm="12" xs="12">
                    <span className="small">Presale Time</span>
                    <Form.Item
                      name="presale_time"
                      rules={[
                        {
                          required: true,
                          message: "The Presale Time field is required!",
                        },
                      ]}
                    >
                      <TimePicker
                        size="large"
                        className="w-100"
                        onChange={onChangePresaleTime}
                      />
                    </Form.Item>
                  </Col>

                  <Col xxl="6" xl="6" lg="6" md="6" sm="12" xs="12">
                    <span className="small">Presale Link</span>
                    <Form.Item
                      name="presale_link"
                      hasFeedback
                      validateStatus={presaleLinkValidateStatus}
                      help={presaleLinkValidateMsg}
                    >
                      <Input
                        prefix={<LinkOutlined />}
                        size="large"
                        placeholder="https://..."
                        onChange={(e) => isValidPresaleAddress(e.target.value)}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              )}
            </Row>

            <h5 className="mt-3">Token Metadata</h5>

            <Row>
              <Col xxl="6" xl="6" lg="6" md="6" sm="12" xs="12">
                <span className="small">Released Date</span>
                <Form.Item
                  name="released_date"
                  rules={[
                    {
                      required: true,
                      message: "The Released Date field is required!",
                    },
                  ]}
                >
                  <DatePicker
                    size="large"
                    className="w-100"
                    onChange={onChangeReleasedDate}
                  />
                </Form.Item>
              </Col>

              <Col xxl="6" xl="6" lg="6" md="6" sm="12" xs="12">
                <span className="small">Released Time</span>
                <Form.Item
                  name="released_time"
                  rules={[
                    {
                      required: true,
                      message: "The Released Time field is required!",
                    },
                  ]}
                >
                  <TimePicker
                    size="large"
                    className="w-100"
                    onChange={onChangeReleasedTime}
                  />
                </Form.Item>
              </Col>

              <Col xxl="6" xl="6" lg="6" md="6" sm="12" xs="12">
                <span className="small">Token Logo Link</span>
                <Form.Item
                  name="token_logo_link"
                  hasFeedback
                  validateStatus={tokenLogoLinkValidateStatus}
                  help={tokenLogoLinkValidateMsg}
                >
                  <Input
                    prefix={<PictureOutlined />}
                    size="large"
                    placeholder="https://..."
                    onChange={(e) => isValidTokenLogoLink(e.target.value)}
                  />
                </Form.Item>
              </Col>

              <Col xxl="6" xl="6" lg="6" md="6" sm="12" xs="12">
                <span className="small">Token Actual Price</span>
                <Form.Item
                  name="actual_price"
                  rules={[
                    {
                      required: true,
                      message: "The Token Actual Price field is required!",
                    },
                  ]}
                >
                  <Input
                    prefix={<DollarCircleOutlined />}
                    size="large"
                    placeholder="0.0002"
                    type="number"
                    onWheel={() => document.activeElement.blur()}
                  />
                </Form.Item>
              </Col>

              <Col xxl="6" xl="6" lg="6" md="6" sm="12" xs="12">
                <span className="small">Token Actual Market Cap</span>
                <Form.Item
                  name="market_cap"
                  rules={[
                    {
                      required: true,
                      message: "The Token Actual Market Cap field is required!",
                    },
                  ]}
                >
                  <Input
                    prefix={<StockOutlined />}
                    size="large"
                    placeholder="5200000"
                    type="number"
                    onWheel={() => document.activeElement.blur()}
                  />
                </Form.Item>
              </Col>

              <Col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12">
                <Form.Item name="is_doxxed">
                  <span className="small">Doxxed </span>
                  <Switch
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    onChange={onChangeIsDoxxed}
                  />
                </Form.Item>
              </Col>

              <Col xxl="6" xl="6" lg="6" md="6" sm="12" xs="12">
                <span className="small">Coin Market Cap Link</span>
                <Form.Item
                  name="coin_market_cap_link"
                  hasFeedback
                  validateStatus={coinMarketCapValidateStatus}
                  help={coinMarketCapValidateMsg}
                >
                  <Input
                    prefix={<LinkOutlined />}
                    size="large"
                    placeholder="https://coinmarketcap.com/currencies/"
                    onChange={(e) => isValidCoinMarketCapLink(e.target.value)}
                  />
                </Form.Item>
              </Col>

              <Col xxl="6" xl="6" lg="6" md="6" sm="12" xs="12">
                <span className="small">Coin Gecko Link</span>
                <Form.Item
                  name="coin_gecko_link"
                  hasFeedback
                  validateStatus={coinGeckoValidateStatus}
                  help={coinGeckoValidateMsg}
                >
                  <Input
                    prefix={<LinkOutlined />}
                    size="large"
                    placeholder="https://www.coingecko.com/en/coins/"
                    onChange={(e) => isValidCoinGeckoLink(e.target.value)}
                  />
                </Form.Item>
              </Col>
            </Row>

            <h5 className="mt-3">Smart Contracts</h5>

            <Row>
              <Col xxl="6" xl="6" lg="6" md="6" sm="12" xs="12">
                <span className="small">Smart Contract Type</span>
                <Form.Item>
                  <Select
                    size="large"
                    className="w-100"
                    value={smartContractType}
                    onChange={handleSmartContractTypeChange}
                    options={[
                      {
                        value: "bsc",
                        label: "Binance Smart Chain",
                      },
                      {
                        value: "eth",
                        label: "Ethereum",
                      },
                      {
                        value: "tron",
                        label: "TRON",
                      },
                      {
                        value: "kcc",
                        label: "KCC",
                      },
                      {
                        value: "solana",
                        label: "Solana",
                      },
                      {
                        value: "polygon",
                        label: "Polygon",
                      },
                      {
                        value: "avalanche",
                        label: "Avalanche",
                      },
                      {
                        value: "maxxchain",
                        label: "MaxxChain",
                      },
                      {
                        value: "dogechain",
                        label: "DogeChain",
                      },
                      {
                        value: "pulsechain",
                        label: "PulseChain",
                      },
                      {
                        value: "basechain",
                        label: "BaseChain",
                      },
                      {
                        value: "shibarium",
                        label: "Shibarium",
                      },
                    ]}
                  />
                </Form.Item>
              </Col>

              {smartContractType === "bsc" && (
                <Col xxl="6" xl="6" lg="6" md="6" sm="12" xs="12">
                  <span className="small">
                    Token Binance Smart Chain contract address
                  </span>
                  <Form.Item name="bsc_address">
                    <Input
                      prefix={<NumberOutlined />}
                      size="large"
                      placeholder="0x23C28E9346C82228FB2b62b20b6fe5d75989F5b2"
                    />
                  </Form.Item>
                </Col>
              )}

              {smartContractType === "eth" && (
                <Col xxl="6" xl="6" lg="6" md="6" sm="12" xs="12">
                  <span className="small">Token Ethereum contract address</span>
                  <Form.Item name="eth_address">
                    <Input
                      prefix={<NumberOutlined />}
                      size="large"
                      placeholder="0x23C28E9346C82228FB2b62b20b6fe5d75989F5b2"
                    />
                  </Form.Item>
                </Col>
              )}

              {smartContractType === "tron" && (
                <Col xxl="6" xl="6" lg="6" md="6" sm="12" xs="12">
                  <span className="small">Token TRON contract address</span>
                  <Form.Item name="tron_address">
                    <Input
                      prefix={<NumberOutlined />}
                      size="large"
                      placeholder="0x23C28E9346C82228FB2b62b20b6fe5d75989F5b2"
                    />
                  </Form.Item>
                </Col>
              )}

              {smartContractType === "kcc" && (
                <Col xxl="6" xl="6" lg="6" md="6" sm="12" xs="12">
                  <span className="small">Token KCC contract address</span>
                  <Form.Item name="kcc_address">
                    <Input
                      prefix={<NumberOutlined />}
                      size="large"
                      placeholder="0x23C28E9346C82228FB2b62b20b6fe5d75989F5b2"
                    />
                  </Form.Item>
                </Col>
              )}

              {smartContractType === "solana" && (
                <Col xxl="6" xl="6" lg="6" md="6" sm="12" xs="12">
                  <span className="small">Token Solana contract address</span>
                  <Form.Item name="solana_address">
                    <Input
                      prefix={<NumberOutlined />}
                      size="large"
                      placeholder="0x23C28E9346C82228FB2b62b20b6fe5d75989F5b2"
                    />
                  </Form.Item>
                </Col>
              )}

              {smartContractType === "polygon" && (
                <Col xxl="6" xl="6" lg="6" md="6" sm="12" xs="12">
                  <span className="small">Token Polygon contract address</span>
                  <Form.Item name="polygon_address">
                    <Input
                      prefix={<NumberOutlined />}
                      size="large"
                      placeholder="0x23C28E9346C82228FB2b62b20b6fe5d75989F5b2"
                    />
                  </Form.Item>
                </Col>
              )}

              {smartContractType === "avalanche" && (
                <Col xxl="6" xl="6" lg="6" md="6" sm="12" xs="12">
                  <span className="small">
                    Token Avalanche contract address
                  </span>
                  <Form.Item name="avalanche_address">
                    <Input
                      prefix={<NumberOutlined />}
                      size="large"
                      placeholder="0x23C28E9346C82228FB2b62b20b6fe5d75989F5b2"
                    />
                  </Form.Item>
                </Col>
              )}

              {smartContractType === "maxxchain" && (
                <Col xxl="6" xl="6" lg="6" md="6" sm="12" xs="12">
                  <span className="small">
                    Token MaxxChain contract address
                  </span>
                  <Form.Item name="maxxchain_address">
                    <Input
                      prefix={<NumberOutlined />}
                      size="large"
                      placeholder="0x23C28E9346C82228FB2b62b20b6fe5d75989F5b2"
                    />
                  </Form.Item>
                </Col>
              )}
              {smartContractType === "dogechain" && (
                <Col xxl="6" xl="6" lg="6" md="6" sm="12" xs="12">
                  <span className="small">
                    Token DogeChain contract address
                  </span>
                  <Form.Item name="dogechain_address">
                    <Input
                      prefix={<NumberOutlined />}
                      size="large"
                      placeholder="0x23C28E9346C82228FB2b62b20b6fe5d75989F5b2"
                    />
                  </Form.Item>
                </Col>
              )}
              {smartContractType === "pulsechain" && (
                <Col xxl="6" xl="6" lg="6" md="6" sm="12" xs="12">
                  <span className="small">
                    Token PulseChain contract address
                  </span>
                  <Form.Item name="pulsechain_address">
                    <Input
                      prefix={<NumberOutlined />}
                      size="large"
                      placeholder="0x23C28E9346C82228FB2b62b20b6fe5d75989F5b2"
                    />
                  </Form.Item>
                </Col>
              )}
              {smartContractType === "basechain" && (
                <Col xxl="6" xl="6" lg="6" md="6" sm="12" xs="12">
                  <span className="small">
                    Token DogeChain contract address
                  </span>
                  <Form.Item name="dogechain_address">
                    <Input
                      prefix={<NumberOutlined />}
                      size="large"
                      placeholder="0x23C28E9346C82228FB2b62b20b6fe5d75989F5b2"
                    />
                  </Form.Item>
                </Col>
              )}
              {smartContractType === "shibarium" && (
                <Col xxl="6" xl="6" lg="6" md="6" sm="12" xs="12">
                  <span className="small">
                    Token Shibarium contract address
                  </span>
                  <Form.Item name="shibarium_address">
                    <Input
                      prefix={<NumberOutlined />}
                      size="large"
                      placeholder="0x23C28E9346C82228FB2b62b20b6fe5d75989F5b2"
                    />
                  </Form.Item>
                </Col>
              )}

              <Col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12">
                <span className="small">
                  Other links, other blockchain contracts or anything else you
                  would like to add to your coin request
                </span>
                <Form.Item name="other_links">
                  <Input.TextArea
                    size="large"
                    placeholder="Bitcoin is an innovative payment network and a new kind of money"
                    rows={3}
                  />
                </Form.Item>
              </Col>
            </Row>

            <h5 className="mt-3">Social</h5>

            <Row>
              <Col xxl="6" xl="6" lg="6" md="6" sm="12" xs="12">
                <span className="small">Website</span>
                <Form.Item
                  name="website"
                  hasFeedback
                  validateStatus={websiteValidateStatus}
                  help={websiteValidateMsg}
                  rules={[
                    {
                      required: true,
                      message: "The Website field is required!",
                    },
                  ]}
                >
                  <Input
                    prefix={<LinkOutlined />}
                    size="large"
                    placeholder="https://..."
                    onChange={(e) => isValidWebsite(e.target.value)}
                  />
                </Form.Item>
              </Col>

              <Col xxl="6" xl="6" lg="6" md="6" sm="12" xs="12">
                <span className="small">Telegram</span>
                <Form.Item
                  name="telegram"
                  hasFeedback
                  validateStatus={telegramValidateStatus}
                  help={telegramValidateMsg}
                >
                  <Input
                    prefix={
                      <SendOutlined
                        rotate={330}
                        style={{ fontSize: "13px", marginTop: "-2px" }}
                      />
                    }
                    size="large"
                    placeholder="https://t.me/bitcoin"
                    onChange={(e) => isValidTelegramLink(e.target.value)}
                  />
                </Form.Item>
              </Col>

              <Col xxl="6" xl="6" lg="6" md="6" sm="12" xs="12">
                <span className="small">Twitter</span>
                <Form.Item
                  name="twitter"
                  hasFeedback
                  validateStatus={twitterValidateStatus}
                  help={twitterValidateMsg}
                >
                  <Input
                    prefix={<TwitterOutlined />}
                    size="large"
                    placeholder="https://twitter.com/bitcoin"
                    onChange={(e) => isValidTwitterLink(e.target.value)}
                  />
                </Form.Item>
              </Col>

              <Col xxl="6" xl="6" lg="6" md="6" sm="12" xs="12">
                <span className="small">Reddit</span>
                <Form.Item
                  name="reddit"
                  hasFeedback
                  validateStatus={redditValidateStatus}
                  help={redditValidateMsg}
                >
                  <Input
                    prefix={<RedditOutlined />}
                    size="large"
                    placeholder="https://reddit.com/bitcoin"
                    onChange={(e) => isValidRedditLink(e.target.value)}
                  />
                </Form.Item>
              </Col>

              <Col xxl="6" xl="6" lg="6" md="6" sm="12" xs="12">
                <span className="small">Discord</span>
                <Form.Item
                  name="discord"
                  hasFeedback
                  validateStatus={discordValidateStatus}
                  help={discordValidateMsg}
                >
                  <Input
                    prefix={<img src={discordLogo} style={{ width: "18px" }} />}
                    size="large"
                    placeholder="https://discord.com/bitcoin"
                    onChange={(e) => isValidDiscordLink(e.target.value)}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item>
              <div className="text-center mt-4">
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={isSubmitLoading}
                >
                  Submit Request
                </Button>
              </div>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </div>
  );
}

export default AddCoin;
