import React, { useState } from 'react'
import bscLogo from '../../images/bsc.png'
import ethLogo from '../../images/eth.png'
import tronLogo from '../../images/tron.png'
import kccLogo from '../../images/kcc.png'
import solanaLogo from '../../images/solana.png'
import polygonLogo from '../../images/polygon.png'
import avalancheLogo from '../../images/avalanche.png'
import { Input, Tooltip, Button } from 'antd'
import { getEllipsisTxt } from '../../Helpers/Formatters'
import { CopyOutlined } from '@ant-design/icons';

function Address(props) {

  const { tokenData } = props
  const [copyStatus, setCopyStatus] = useState('Copy')

  const handleCopy = (value) => {
    navigator.clipboard.writeText(value)
    setCopyStatus('Copied')
    setTimeout(function () {
      setCopyStatus('Copy')
    }, 1000);
  }


  const getTokenAddress = () => {
    if (tokenData?.bsc_contract_address) {
      return (
        <div className='d-flex mt-2'>
          <Input.Group compact>
            <img src={bscLogo} alt="bsc-logo" className='p-1 token-logo' style={{ marginTop: '1px' }} />
            <p style={{ marginTop: '5px' }} className="text-muted">
              {getEllipsisTxt(tokenData?.bsc_contract_address, 10)}
            </p>
            <Tooltip title={copyStatus}>
              <Button type="text" icon={<CopyOutlined />} onClick={() => handleCopy(tokenData?.bsc_contract_address)} />
            </Tooltip>
          </Input.Group>
        </div>
      )
    } else if (tokenData?.ethereum_contract_address) {
      return (
        <div className='d-flex mt-2'>
          <Input.Group compact>
            <img src={ethLogo} alt="eth-logo" className='p-1 token-logo' style={{ marginTop: '1px' }} />
            <p style={{ marginTop: '5px' }} className="text-muted">
              {getEllipsisTxt(tokenData?.ethereum_contract_address, 10)}
            </p>
            <Tooltip title={copyStatus}>
              <Button type="text" icon={<CopyOutlined />} onClick={() => handleCopy(tokenData?.ethereum_contract_address)} />
            </Tooltip>
          </Input.Group>
        </div>
      )
    } else if (tokenData?.tron_token) {
      return (
        <div className='d-flex mt-2'>
          <Input.Group compact>
            <img src={tronLogo} alt="tron-logo" className='p-1 token-logo' style={{ marginTop: '1px' }} />
            <p style={{ marginTop: '5px' }} className="text-muted">
              {getEllipsisTxt(tokenData?.tron_token, 10)}
            </p>
            <Tooltip title={copyStatus}>
              <Button type="text" icon={<CopyOutlined />} onClick={() => handleCopy(tokenData?.tron_token)} />
            </Tooltip>
          </Input.Group>
        </div>
      )
    } else if (tokenData?.kcc_token) {
      return (
        <div className='d-flex mt-2'>
          <Input.Group compact>
            <img src={kccLogo} alt="kcc-logo" className='p-1 token-logo' style={{ marginTop: '1px' }} />
            <p style={{ marginTop: '5px' }} className="text-muted">
              {getEllipsisTxt(tokenData?.kcc_token, 10)}
            </p>
            <Tooltip title={copyStatus}>
              <Button type="text" icon={<CopyOutlined />} onClick={() => handleCopy(tokenData?.kcc_token)} />
            </Tooltip>
          </Input.Group>
        </div>
      )
    } else if (tokenData?.solana_token) {
      return (
        <div className='d-flex mt-2'>
          <Input.Group compact>
            <img src={solanaLogo} alt="solana-logo" className='p-1 token-logo' style={{ marginTop: '1px' }} />
            <p style={{ marginTop: '5px' }} className="text-muted">
              {getEllipsisTxt(tokenData?.solana_token, 10)}
            </p>
            <Tooltip title={copyStatus}>
              <Button type="text" icon={<CopyOutlined />} onClick={() => handleCopy(tokenData?.solana_token)} />
            </Tooltip>
          </Input.Group>
        </div>
      )
    } else if (tokenData?.polygon_token) {
      return (
        <div className='d-flex mt-2'>
          <Input.Group compact>
            <img src={polygonLogo} alt="polygon-logo" className='p-1 token-logo' style={{ marginTop: '1px' }} />
            <p style={{ marginTop: '5px' }} className="text-muted">
              {getEllipsisTxt(tokenData?.polygon_token, 10)}
            </p>
            <Tooltip title={copyStatus}>
              <Button type="text" icon={<CopyOutlined />} onClick={() => handleCopy(tokenData?.polygon_token)} />
            </Tooltip>
          </Input.Group>
        </div>
      )
    } else if (tokenData?.avalanche_token) {
      return (
        <div className='d-flex mt-2'>
          <Input.Group compact>
            <img src={avalancheLogo} alt="avalanche-logo" className='p-1 token-logo' style={{ marginTop: '1px' }} />
            <p style={{ marginTop: '5px' }} className="text-muted">
              {getEllipsisTxt(tokenData?.avalanche_token, 10)}
            </p>
            <Tooltip title={copyStatus}>
              <Button type="text" icon={<CopyOutlined />} onClick={() => handleCopy(tokenData?.avalanche_token)} />
            </Tooltip>
          </Input.Group>
        </div>
      )
    }
  }

  return (
    getTokenAddress()
  )
}

export default Address