import { Card } from "antd";
import React, { useState, useEffect } from "react";
import { ArrowRightOutlined } from '@ant-design/icons';
import Brc20Table from '../../Components/Tables/Brc20Table'
import './Brc20.css'
import axios from 'axios';
import NumberFormat from 'react-number-format';
import AddHere from '../../Components/AddHere/AddHere'
function Brc20Tokens() {

    const [brc20TokensLength, setBrc20TokensLength] = useState(0)
    const [brc20TokensLengthLoading, setBrc20TokensLengthLoading] = useState(false)
    const [brc20MarketCap, setBrc20MarketCap] = useState(0)

    const fetchBrc20Tokens = async () => {
        setBrc20TokensLengthLoading(true)
        try {
          const response = await axios.get(
            "https://api.ordspace.org/brc-20/statistic"
          );
          setBrc20TokensLength(response.data.data.coins);
          setBrc20MarketCap(response.data.data.market.uniSat)
          setBrc20TokensLengthLoading(false)
        } catch (error) {
          console.error(error);
          setBrc20TokensLengthLoading(false)
        }
      };

      useEffect(() => {
        fetchBrc20Tokens()
        const interval = setInterval(() => {
            fetchBrc20Tokens()
        }, 60000) // fetch data every 60 seconds
        return () => clearInterval(interval)
    }, [])

    return (
        <div className='container-lg'>
            <div className='page-header'>
                <AddHere />
                <div className="d-flex justify-content-between">
                    <h3 className="">Brc20 (<span className="letter-blue">{brc20TokensLength}</span> Tokens)</h3>
                    {/* <div className="d-flex">
                        <Input placeholder="Search address" size="large" />
                        <Button style={{marginLeft: '20px', marginTop: '4px'}} type='primary' size="large"><ArrowRightOutlined /></Button>
                    </div> */}
                </div>
                
                <div className="mt-3">
                    <Card className='home-tabs-card mb-5'>
                        <Brc20Table brc20MarketCap={brc20MarketCap} brc20TokensLengthLoading={brc20TokensLengthLoading} />
                    </Card>
                </div>
            </div>
        </div>
    )
}

export default Brc20Tokens