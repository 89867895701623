import React, { useEffect, useState } from "react";
import axios from 'axios';
import { Table, Input, Spin, Button } from 'antd'
import TickerName from '../TickerName/TickerName'
import NumberFormat from 'react-number-format';
import { Col, Row } from "reactstrap";
import api from '../../Services/Api';
import RiskLevel from '../RiskLevel/RiskLevel'
import VoteButtonRiskLevel from '../VoteButton/VoteButtonRiskLevel'

function Brc20Table(props) {

    const { Search } = Input;

    const { brc20MarketCap, brc20TokensLengthLoading } = props

    const [tableData, setTableData] = useState([])
    const [tableDataLoading, setTableDataLoading] = useState(false)
    const [searchValue, setSearchValue] = useState('')
    const [pageNumber, setPageNumber] = useState(1)
    const [inscriptionNumbersMetaData, setInscriptionNumbersMetaData] = useState()
    const [isMetadataLoading, setIsMetadataLoading] = useState(false)
    const [userVotesTokens, setUserVotesTokens] = useState()
    const [userVotedCoinsLoading, setUserVotedCoinsLoading] = useState(false)
    const clientIP = localStorage.getItem('rfc_client_ip') ? localStorage.getItem('rfc_client_ip') : null

    useEffect(() => {
        fetchBrc20TokenData()
        const interval = setInterval(() => {
            fetchBrc20TokenData()
            fetchUserVotedCoins()
        }, 60000) // fetch data every 5 seconds
        return () => clearInterval(interval)
    }, [])

    const fetchBrc20TokenData = async () => {
        setTableDataLoading(true)
        try {
          const response = await axios.get(
            `https://api.ordspace.org/brc-20?page=${pageNumber}&orderBy=market&s=`
            // `https://api.coinranking.com/v2/coins?limit=50`
          );

          const newData = [...tableData, ...response.data.data];
            setTableData(newData);

            const tokenIds = newData.map(item => item.inscriptionNumber);

            const url = 'https://backend.rugfreecoins.com/api/v1/coins/brc20/token/metadata/get-by-list-ids';

            const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
            };
            const data = {
            token_ids: tokenIds
            };

            try {
                const response = await axios.post(url, data, { headers });
                setInscriptionNumbersMetaData(response.data.payload)
                console.log('response>response', response.data);

            } catch (error) {
                console.log('Failed to send data.');
                console.log(error);
                // Handle error
            }


          setTableDataLoading(false)
        } catch (error) {
          console.error(error);
          setTableDataLoading(false)
        }
      };

    const handleNext = () => {
        let _pageNumber = pageNumber
        setPageNumber(_pageNumber + 1)
    }

    const FetchMetadataByInscriptionNumber = ({ id, tableData, inscriptionNumbersMetaData }) => {
        let risk_level = 'DYOR'; 
      
        const matchingData = tableData.find(item => item.inscriptionNumber === id);
        if (matchingData && Array.isArray(inscriptionNumbersMetaData)) {
          const hasMatchingMetaData = inscriptionNumbersMetaData.some(metaData => metaData.inscription_number === id);
      
          if (hasMatchingMetaData) {
            risk_level = inscriptionNumbersMetaData.find(metaData => metaData.inscription_number === id).risk_level;
          }
        }
      
        return <RiskLevel riskLevel={risk_level} />;
      };

      useEffect(() => {
        fetchUserVotedCoins()
    }, [clientIP])

      const fetchUserVotedCoins = async () => {
        setUserVotedCoinsLoading(true)
        try {
            const response = await axios.get(
                `https://backend.rugfreecoins.com/api/v1/coins/brc20/token/get-voted-tokens/${clientIP}`
              );

              if (response.status === 200) {
                setUserVotesTokens(response.data.payload)
                setUserVotedCoinsLoading(false)
              }


        } catch (error) {
            setUserVotedCoinsLoading(false)
        }
    }
            
    const FetchVoteCount = ({ id, tableData, inscriptionNumbersMetaData }) => {
        let vote_count = 0; 
        let is_voted = false
      
        const matchingData = tableData.find(item => item.inscriptionNumber === id);
        if (matchingData && Array.isArray(inscriptionNumbersMetaData)) {
          const hasMatchingMetaData = inscriptionNumbersMetaData.some(metaData => metaData.inscription_number === id);
      
          if (hasMatchingMetaData) {
            vote_count = inscriptionNumbersMetaData.find(metaData => metaData.inscription_number === id).votes;
          }
        }
        if (matchingData && Array.isArray(userVotesTokens)) {
          const hasMatchingVoteIds = userVotesTokens.some(metaData => metaData.inscription_number === id);
      
          if (hasMatchingVoteIds) {
            is_voted = true;
          }
        }
      
        return <VoteButtonRiskLevel voteCount={vote_count} isVoted={is_voted} coinID={id} onVoteButtonClick={handleVoteButtonClick} />;
      };
      
      const handleVoteButtonClick = () => {
        fetchUserVotedCoins()
        fetchBrc20TokenData()
        // Perform any necessary actions or updates
      };

    useEffect(() => {
        fetchBrc20TokenData()
    }, [pageNumber])

    const columns = [
        {
            key: "id",
            title: '#',
            dataIndex: "id",
            align: "center",
            render: (_, record, index) => <span className="row-no">{(index + 1)}</span>,
        },
        {
            key: "ticker",
            title: 'NAME',
            fixed: 'left',
            dataIndex: "ticker",
            render: (_, record) => (
                <TickerName id={record.inscriptionNumber} tokenName={record.ticker} />
            ),
        },
        {
            key: "Price",
            title: "PRICE",
            dataIndex: "price",
            render: (_, record) => {
                const formattedPrice = parseFloat(record.price).toLocaleString(undefined, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 10,
                  });
                  return <span>{formattedPrice}</span>;
            },
        },
        {
            key: "change",
            title: "24H%",
            dataIndex: "change",
            render: (_, record) => 
                <span className={` ${record.change < 0 ? 'red-span' : 'green-span'}`}>
                    {record.change} %
                </span>
        },
        {
            key: "market",
            title: "MARKET CAP",
            dataIndex: "market",
            render: (_, record) =>
                <NumberFormat
                    displayType='text'
                    decimalScale={0}
                    value={record.market ? record.market : 0}
                    prefix="$"
                    thousandSeparator={true}
                />,
        },
        {
            key: "val",
            title: "VOLUME (24H)",
            dataIndex: "BtcVolume.val",
            render: (_, record) => 
                <NumberFormat
                    displayType='text'
                    decimalScale={2}
                    value={record.BtcVolume.val ? record.BtcVolume.val : 0}
                    prefix="$"
                    thousandSeparator={true}
                 />,
        },
        {
            key: "max",
            title: "SUPPLY",
            dataIndex: "max",
            render: (_, record) => 
                <NumberFormat
                    displayType='text'
                    decimalScale={2}
                    value={record.max ? record.max : 0}
                    thousandSeparator={true}
                />,
        },
        {
            key: "holdersCount",
            title: "HOLDERS",
            dataIndex: "holdersCount",
            
            render: (_, record) => <span className="">{record.holdersCount} </span>,
        },
        {
            key: "risk_level",
            title: 'Risk Level',
            render: (_, record) => <FetchMetadataByInscriptionNumber id={record.inscriptionNumber} tableData={tableData} inscriptionNumbersMetaData={inscriptionNumbersMetaData} />,
        },
        {
            key: "votes",
            title: 'Votes',
            dataIndex: "vote_count",
            align: "end",
            render: (_, record) => <div>
                <FetchVoteCount id={record.inscriptionNumber} tableData={tableData} inscriptionNumbersMetaData={inscriptionNumbersMetaData}  />
            </div>
        }
    ];

    const filteredData = tableData.filter((data) => {
        return (
            data.ticker.toLowerCase().includes(searchValue.toLowerCase())
        )
    })

    return (
        <div>
            <div className="px-3 pt-3 mt-3 pb-2 mt-md-2">
                <Row>
                    <Col xxl="8" xl="8" lg="8" md="12" sm="12" xs="12">
                        <Search 
                            placeholder="Search token name" 
                            allowClear 
                            size="large" 
                            className='brc-20-search'
                            onSearch={(value) => setSearchValue(value)}
                        />
                    </Col>
                    <Col xxl="4" xl="4" lg="4" md="12" sm="12" xs="12" className="mobile-margin-top d-flex flex-column justify-content-center">
                        <span>The total BRC-20 market cap is &nbsp;
                            {
                                brc20TokensLengthLoading ? (
                                    <div className="d-flex justify-content-center page-header">
                                        <Spin size='small' />
                                    </div>
                                ) : (
                                    <span className="letter-blue">
                                        <NumberFormat
                                            displayType='text'
                                            decimalScale={2}
                                            value= {brc20MarketCap}
                                            prefix="$"
                                            thousandSeparator={true}
                                        />
                                    </span>
                                )
                            }
                        
                        </span>
                    </Col>
                </Row>
            </div>
            <div className='table'>
                <div className='table-responsive'>
                    <Table
                        rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'}
                        size='small'
                        columns={columns}
                        // dataSource={tableData}
                        dataSource={filteredData}
                        pagination={false}
                        loading={tableDataLoading}
                        className='px-2'
                    />
                    <div className='text-center'>
                        <div className='service-button'>
                            {
                                !tableDataLoading &&
                                <Button 
                                    type="primary"
                                    onClick={() => handleNext()}
                                >
                                    See More
                                </Button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Brc20Table;
